var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.pageloading),expression:"pageloading"}],staticClass:"culture-medicine-table",attrs:{"element-loading-background":"white"}},[(_vm.pageloading)?_c('div',{staticClass:"loading_class"}):_vm._e(),_vm._v(" "),_c('er-data-tables',{directives:[{name:"show",rawName:"v-show",value:(!_vm.pageloading),expression:"!pageloading"}],key:_vm.$i18n.locale,attrs:{"type":"white-header-table","table-data":_vm.tableDataArr,"uniqueId":"medicine-table","filters":_vm.filters,"columns":_vm.tableColumns,"el-table-props":_vm.tableProps,"paginationSlot":true,"actionColumn":false},scopedSlots:_vm._u([{key:"pond_id",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"pond-container"},[_c('er-select',{key:_vm.getDate,attrs:{"size":"mini","placeholder":_vm.$t('Ponds_select_pond_name')},model:{value:(row.data.pond_id),callback:function ($$v) {_vm.$set(row.data, "pond_id", $$v)},expression:"row.data.pond_id"}},_vm._l((_vm.getPondsInCulture),function(pond){return _c('el-option',{key:pond._id,attrs:{"value":pond._id,"label":pond.title}})}),1)],1)]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_c('er-select',{attrs:{"size":"mini","placeholder":_vm.$t('Medicines_variety')},on:{"change":function($event){return _vm.handleChangeResourceType($event, 'type', row.data)}},model:{value:(row.data.type),callback:function ($$v) {_vm.$set(row.data, "type", $$v)},expression:"row.data.type"}},_vm._l((_vm.resourceTypes),function(resourceLabel,resourceKey){return _c('el-option',{key:resourceKey,attrs:{"value":resourceKey,"label":_vm.$t(resourceLabel)}})}),1)]}},{key:"resource_id",fn:function(ref){
var row = ref.row;
return [(row.data.type != 'OTHER')?_c('div',[_c('er-select',{attrs:{"size":"mini","placeholder":_vm.$t('Comn_resource')},on:{"change":function($event){return _vm.handleChangeResourceType($event, 'resource', row.data)}},model:{value:(row.data.resource_id),callback:function ($$v) {_vm.$set(row.data, "resource_id", $$v)},expression:"row.data.resource_id"}},_vm._l((_vm.getObjOfResourcesByType[row.data.type]),function(resource){return _c('el-option',{key:resource._id,attrs:{"value":resource._id,"label":resource.name}})}),1)],1):_c('div',[_c('el-input',{staticClass:"others-input",attrs:{"placeholder":_vm.$t('Comn_others'),"size":"mini"},model:{value:(row.data.resource_name),callback:function ($$v) {_vm.$set(row.data, "resource_name", $$v)},expression:"row.data.resource_name"}})],1)]}},{key:"notes",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"type":"textarea","autosize":"","rows":2,"placeholder":_vm.$t('Comn_note_here')},model:{value:(row.data.notes),callback:function ($$v) {_vm.$set(row.data, "notes", $$v)},expression:"row.data.notes"}})]}},{key:"quantity",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"quantity-input"},[_c('el-input-number',{attrs:{"placeholder":_vm.$t('Comn_quantity'),"controls":false,"size":"mini","min":0,"max":100000},model:{value:(row.data.quantity),callback:function ($$v) {_vm.$set(row.data, "quantity", $$v)},expression:"row.data.quantity"}}),_vm._v(" "),_c('er-select',{attrs:{"size":"mini","disabled":row.data.type != 'OTHER',"placeholder":_vm.$t('Comn_units')},model:{value:(row.data.units),callback:function ($$v) {_vm.$set(row.data, "units", $$v)},expression:"row.data.units"}},_vm._l((_vm.units),function(unit){return _c('el-option',{key:unit.key,attrs:{"value":unit.key,"label":unit.name}})}),1)],1)]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [(row.$index === _vm.tableDataLength - 1)?[_c('er-button',{attrs:{"btnType":"add","type":"text","size":"mini","showLabel":true,"showIcon":true,"disabled":_vm.isReadOnly},on:{"click":function($event){return _vm.handleAddRow()}}})]:[_c('er-button',{attrs:{"btnType":"delete","type":"text","showLabel":true,"showIcon":true,"size":"mini","disabled":_vm.isReadOnly},on:{"click":function($event){return _vm.handleDeleteRow(row.$index)}}})]]}}])},[_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),_c('template',{slot:"pagination"},[_c('er-button',{attrs:{"btnType":"save","showLabel":true,"showIcon":true,"loading":_vm.loading,"size":"mini","disabled":_vm.isReadOnly},on:{"click":_vm.handleSaveTableData}}),_vm._v(" "),_c('er-button',{attrs:{"btnType":"cancel","showLabel":true,"size":"mini","disabled":_vm.isReadOnly},on:{"click":_vm.handleCancel}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }